import { Vue } from './common/vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import { routes } from './routes/marketing'

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

import Marketing from './components/marketing.vue'

new Vue({
  el: '#marketing',
  render: h => h(Marketing),
  router
}) 

document.addEventListener('keydown', function (e) {
    if (e.keyCode == 119) { // F8
        debugger;
    }
}, {
    capture: true
});