<template>
  <div class="wrapper">
    <img src="../../images/shape.svg" class="shape2" />

    <div class="container">
      <header>
        <a class="logo" href="/">
          <img src="../../images/logo.svg" width="125" />            
        </a>

        <!-- <nav class="font-sm">
          <a href="mailto:contact@mvtechnologies.ca"><b>Got questions?</b> contact us: contact@mvtechnologies.ca</a>

          <a href="/demo" target="_blank"><b>Live Demo</b></a>
          <a href="/auth/signup"><b>Sign up as a business</b></a>

          <a href="/auth/login" class="button button-small button-ghost">My account</a>
        </nav> -->
      </header>

      <router-view></router-view>

      <footer>
        <img src="../../images/logo_symbol.svg" width="25" class="logo" />

        <p class="font-xs">Made with love in Canada</p>
        <p class="font-xs color-gray">© 2021, GuestLog Inc. All rights reserved.</p>
        <p class="font-xs color-gray"><a href="/terms">Terms of service</a>  ·  <a href="/client_privacy">Client Privacy policy</a>  ·  <a href="/privacy">End-User Privacy policy</a>  ·  <a href="mailto:contact@mvtechnologies.ca">Contact us</a></p>
      </footer>
    </div>
  </div>
</template>

<script>
import '../../css/marketing/marketing.scss'

export default {
  components: { },

  data(){
    return {
    }
  },

  created(){
  },

  methods: {
  },

  mounted(){
  },

  computed: {
  },

  watch: {
  }
}
</script>