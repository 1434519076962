import Vue from 'vue'

const _ = require('./lodash').default
import {Dragster} from '@matrx/dragster'

Vue.mixin({
  methods: {

    $set_interval(vm, fn, ms){
      let int = setInterval(fn, ms)

      vm.$once("hook:beforeDestroy", () => {
        clearInterval(int)
      })
    },

    // Helper to hook up to bus events without leaking memory
    $hook_bus(vm, ev, func){
      vm.$bus.$on(ev, func)

      vm.$once("hook:beforeDestroy", () => {
        vm.$bus.$off(ev, func)
      })
    },

    // Helper to update objects within arrays or destroy them
    // based on payloads received from pusher
    $apply_payload(target, payload, push_func='unshift', inclusion_control=null){
      if(Array.isArray(target)){
        if(payload._destroyed){
          let ix = target.findIndex((o) => o.id == payload.id )
          
          if(ix > -1){
            target[ix]._destroyed = true
            target.splice(ix, 1)
          }
        }else{
          // remove from target array if no longer meets current filter controls
          let to_dispose = inclusion_control && !inclusion_control(payload, target)

          if(to_dispose){
            let i = _.findIndex(target, { id: payload.id })
            if(i > -1){ target.splice(i, 1) }
            return 
          }

          // Otherwise mutate target, or push if new record
          let ex = _.find(target, { id: payload.id })

          if(ex){
            _.extend(ex, payload)
          }else{
            if(push_func !== false){
              if(typeof push_func === 'function'){
                push_func(payload, target)
              }else{
                target[push_func](payload)
              }
            }
          }
        }
      }else if(target && target.id == payload.id){
        if(payload._destroyed){
          target._destroyed = true
        }else{
          _.extend(target, payload)
        }
      }
    },

    money_format(n){
      return n.toFixed(2)
    },

    number_with_commas(n){
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    bytes_to_size(bytes, seperator = '') {
      const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb']
      if (bytes == 0) return 'empty'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes}${seperator}${sizes[i]}`
      return `${(bytes / (1024 ** i)).toFixed(1)}${seperator}${sizes[i]}`
    },

    truncate_middle(fullStr, strLen, separator) {
      if (fullStr.length <= strLen) return fullStr

      separator = separator || '...'

      var sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow/2),
        backChars = Math.floor(charsToShow/2)

      return fullStr.substr(0, frontChars) + 
               separator + 
               fullStr.substr(fullStr.length - backChars)
    },
    
    pluralize(n, s){
      return `${n} ${s}${n == 1 ? '' : 's'}`
    },

    to_percent(a, b){
      return (Math.round((b / a) * 100) || 0) + '%'
    },

    uuid(){
      var uuid = '', i, random
      for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0

        if (i == 8 || i == 12 || i == 16 || i == 20) {
          uuid += '-'
        }
        uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16)
      }
      return uuid
    },

    scroll_to_top(){
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },

    _copy(text){
      let dummy = document.createElement('textarea')
      document.body.appendChild(dummy)
      dummy.value = text
      dummy.select()
      document.execCommand('copy')
      document.body.removeChild(dummy)
    }
  },

  computed: {
    _() {
      return _
    },

    $bus(){
      return require('./bus').bus
    },

    $axios(){
      return require('./axios').axios
    },

    is_production(){
      return process.env.NODE_ENV == 'production'
    },

    current_route_name() {
      return this.$route.name
    },

    is_inspect_mode(){
      return document.querySelector('meta[name=inspect-mode]') ? true : false
    },
  },

  directives: {
    'autofocus': {
      inserted(el, binding) {
        // If directive has bound value
        if (binding.value !== undefined && !binding.value) {
          return
        }
        // Focus the element
        if(el.tagName.match(/input|textarea/i)){
          el.focus()
        }else{
          let input = el.querySelector('input:not([type="hidden"]), textarea')

          if(input){
            input.focus()
          }
        }
      }
    },

    'near-end': {
      bind: function(el, binding) {
        let handler = _.throttle((e) => {
          console.log((el.scrollHeight - (el.scrollTop + el.offsetHeight)))

          if((el.scrollHeight - (el.scrollTop + el.offsetHeight)) < 200){
            binding.value(e)
          }
        }, 200, { trailing: true, leading: true })

        el.__vueNearEnd__ = handler

        // add Event Listeners
        el.addEventListener('scroll', handler)
      },
      
      unbind: function(el) {
        // Remove Event Listeners
        el.removeEventListener('scroll', el.__vueNearEnd__)
        el.__vueNearEnd__ = null
      }
    },

    'isolated-scroll': {
      bind: function (el, binding) {
        var defaultOptions = {
          whenOnlyHasScrollbar: false,
          isolateX: true,
          isolateY: true
        }

        var options = binding.value || defaultOptions
        Object.keys(defaultOptions).forEach(function(option) {
          if (!options.hasOwnProperty(option)) {
            options[option] = defaultOptions[option]
          }
        })
        
        el.addEventListener('wheel', function (event) {
          var scrollWidth = el.scrollWidth
          var scrollHeight = el.scrollHeight
          var clientWidth = el.clientWidth
          var clientHeight = el.clientHeight
          var hasScrollX = scrollWidth !== clientWidth
          var hasScrollY = scrollHeight !== clientHeight
          
          if (options.whenOnlyHasScrollbar && (!hasScrollX || !hasScrollY)) {
            return
          }
          
          var isHorizontalScroll = event.deltaX !== 0
          var isVerticalScroll = event.deltaY !== 0
          var isIsolatedScroll = false

          if (isHorizontalScroll && options.isolateX) {
            var scrollLeft = el.scrollLeft
            var meetLeft = scrollLeft === 0
            var meetRight = (scrollWidth - clientWidth) === scrollLeft
            var isLeft = event.deltaX < 0
            isIsolatedScroll = (meetLeft && isLeft) || (meetRight && !isLeft)
            console.log(isIsolatedScroll)
          }
          
          if (isVerticalScroll && options.isolateY) {
            var scrollTop = el.scrollTop
            var meetTop = scrollTop === 0
            var meetBottom = (scrollHeight - clientHeight) === scrollTop
            var isUpward = event.deltaY < 0
            isIsolatedScroll = (meetBottom && !isUpward) || (meetTop && isUpward)
          }
          
          if (isIsolatedScroll) {
            event.preventDefault()
            event.stopPropagation()
          }
        })
      },

      unbind: function (el) {
        el.removeEventListener('wheel', function () {
          console.log('removed')
        })    
      }
    },

    'click-outside': {
      bind: function(el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
          const compName = vNode.context.name
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
          if (compName) { warn += `Found in component '${compName}'` }
          
          console.warn(warn)
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler

        // add Event Listeners
        document.addEventListener('click', handler)
      },
      
      unbind: function(el) {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null

      }
    },

    'mousedown-outside': {
      bind: function(el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
          const compName = vNode.context.name
          let warn = `[Vue-mousedown-outside:] provided expression '${binding.expression}' is not a function, but has to be`
          if (compName) { warn += `Found in component '${compName}'` }
          
          console.warn(warn)
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.__vueMouseDownOutside__ = handler

        // add Event Listeners
        document.addEventListener('mousedown', handler)
      },
      
      unbind: function(el) {
        // Remove Event Listeners
        document.removeEventListener('mousedown', el.__vueMouseDownOutside__)
        el.__vueMouseDownOutside__ = null

      }
    }
  }
})

export { Vue }