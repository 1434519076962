import Website from '../components/marketing.vue'
import Index from '../components/marketing/index.vue'
import Terms from '../components/marketing/terms.vue'
import UserPrivacy from '../components/marketing/user_privacy.vue'
import ClientPrivacy from '../components/marketing/client_privacy.vue'

const routes = [
  { path: '/', component: Index, name: 'index' },
  { path: '/terms', component: Terms, name: 'terms' },
  { path: '/privacy', component: UserPrivacy, name: 'user_privacy' },
  { path: '/client_privacy', component: ClientPrivacy, name: 'client_privacy' },
  // { path: '/auth/signup', component: Signup, name: 'signup' },
  // { path: '/auth/reset_password', component: ResetPassword, name: 'reset_password' }
]

export { routes }